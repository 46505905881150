.view {
  .btn {
    &.btn-primary {
      background: lighten($bhg-black, 10%);
      border-color: lighten($bhg-black, 10%);

      &:focus, &:active {
        background: lighten($bhg-black, 20%);
        border-color: lighten($bhg-black, 20%);
      }
    }
  }
}
