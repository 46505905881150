.view.auth.login {
  padding-top: 300px;

  .bhg-card {
    max-width: 550px;
    background: lighten($bhg-black, 5%);
    padding: 40px;

    .logo  {
      max-width: 60%;
      margin-bottom: 20px;
    }

    .login-form {
      margin: 20px 0 0;

      .btn {
        margin: 0;
      }
    }
  }
}
