.page-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .view-content {
    flex: 1;
    color: $white;
    padding: 50px;
    height: 100%;
    overflow-y: auto;

    > * {
      margin-bottom: 10px;
    }
  }
}

.view {
  .logo {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    img {
      max-width: 60%;
    }

    span {
      color: $white;
      line-height: 45px;
      font-size: 30px;
      margin-left: 10px;
    }
  }

  .form-control.admin-input {
    background: rgba(0, 0, 0, 0.25);
    color: $white;
    transition: all .5s;

    &:focus, &:active {
      background: rgba(0, 0, 0, 0.45);
      color: $white;
    }
  }

  .bhg-input-wrapper {
    text-align: left;

    label {
      color: $white;
    }

    .bhg-input-group .input-err-message {
      background: transparent;
    }
  }

  .sidebar {
    max-width: 300px;
    padding: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-radius: 0 20px 20px 0;
    box-shadow: 8px 0px 8px 0px rgba(255,255,255,0.2);

    .logo {
      margin: 0 40px 25px;

      img {
        max-width: 100%;
      }
    }

    hr {
      border-color: $white;
      margin: 1rem -15px;
    }

    .projects-list {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100%;

      a {
        color: $white;
      }
    }

    .actions {
      padding-top: 20px;
    }
  }
}


//temp

.view.table {
  margin: 0;

  .bhg-card {
    background: $bhg-black;
    height: 100%;
    overflow-y: auto;
    color: $white;
    text-align: left;

    * {
      color: $white;
      border-color: $white;

    }

    input, textarea {
      background: $bhg-black;
      margin: 0;
    }

    td, th {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdown-menu {
      background: $bhg-black;

      .dropdown-item {
        &:hover {
          a {
            color: $bhg-black;
          }
        }
      }
    }

    .page-item {
      &.active {
        .page-link {
          color: $white;
          background: $bhg-black;
        }
      }

      .page-link {
        color: $bhg-black;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .react-bootstrap-table-editing-cell {
      position: absolute;
      background: $bhg-black;
      z-index: 100;

      textarea {
        resize: both;
      }
    }
  }
}
